.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.collection-page {
  /* 列表为空*/
}
.collection-page .collection-list {
  padding: 0.3rem;
}
.collection-page .yd-list-donetip {
  font-size: 0.28rem;
  color: #999;
  margin-bottom: 1.88rem;
}
.collection-page .collection-item {
  height: 2.2rem;
  background: #fff;
  -webkit-box-shadow: 0 0.06rem 0.4rem 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 0.06rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
  margin-bottom: 0.2rem;
  /*商品信息*/
}
.collection-page .collection-item .goods-img {
  width: 2.2rem;
  height: 2.2rem;
  border-bottom-left-radius: 0.08rem;
  border-top-left-radius: 0.08rem;
}
.collection-page .collection-item .goods-info {
  width: 4.7rem;
  height: 100%;
  padding: 0.24rem 0.1rem 0.2rem 0.2rem;
  /*价格*/
  /*收藏按钮*/
}
.collection-page .collection-item .goods-info .title {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.collection-page .collection-item .goods-info .title .source-name {
  position: relative;
  top: -0.02rem;
  display: inline-block;
  height: 0.3rem;
  line-height: 0.35rem;
  padding: 0 0.15rem;
  border-radius: 0.2rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
.collection-page .collection-item .goods-info .describe {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  margin-bottom: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.collection-page .collection-item .goods-info .original-price {
  color: #919193 !important;
  font-size: 0.3rem !important;
}
.collection-page .collection-item .goods-info .price {
  font-size: 0.32rem;
  color: #333;
  font-weight: bold;
  margin-top: 0.2rem;
  width: 4rem;
}
.collection-page .collection-item .goods-info .price .old {
  font-size: 0.24rem;
  color: #999;
  text-decoration: line-through;
  margin-left: 0.12rem;
}
.collection-page .collection-item .goods-info .zjg-max {
  color: var(--main-color);
  font-size: 0.2rem;
}
.collection-page .collection-item .goods-info .btn {
  width: 1.46rem;
  height: 0.52rem;
  margin-top: 0.15rem;
  border-radius: 0.26rem;
  border: 0.02rem solid var(--main-color);
  font-size: 0.24rem;
  color: var(--main-color);
  line-height: 0.52rem;
  text-align: center;
}
.collection-page .collection-item .goods-info .lusse {
  font-size: 0.2rem;
}
.collection-page .collection-item .goods-info .lusse .plans {
  color: #d5474d;
}
.collection-page .collection-item .goods-info .lusse .money {
  font-size: 0.4rem;
}
.collection-page .empty-box {
  background: #fff;
  height: 100vh;
}
